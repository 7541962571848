import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    redirect: () => {
      if (!sessionStorage.getItem('account')) {
        return { name: 'login' }
      } else {
        return { name: 'home' }
      }
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/allView.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/passWordManage',
        name: 'passWordManage',
        component: () => import(/* webpackChunkName: "about" */ '../views/passWordManage.vue')
      }
      ,
      {
        path: '/userManage',
        name: 'userManage',
          component: () => import(/* webpackChunkName: "about" */ '../views/userManage.vue')
      }
      ,
      {
        path: '/machineUpdate',
        name: 'machineUpdate',
        component: () => import(/* webpackChunkName: "about" */ '../views/machineUpdate.vue')
      }
    ]
  }, 
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/loginView.vue')
  },
  {
    path: '/logOut',
    name: 'logOut',
    component: () => import('@/views/logOut.vue')
  },
  {
    path: '/firstLogin',
    name: 'firstLogin',
    component: () => import('@/views/firstLogin.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.name == 'login') {
    next()
    return
  }
  if (!sessionStorage.getItem('account')){
    next({ name: 'login' })
  }
  var role = sessionStorage.getItem('roles')
  if (to.name == 'userManage') {
    if (role != '1') {
      next({ name: 'home' })
      return
    }
  }
  if (to.name == 'machineUpdate') {
    if (role != '2') {
      next({ name: 'home' })
      return
    }
  }
  next()
})
export default router

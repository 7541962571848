// @ts-nocheck
import router from './router'
import store from './store'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import 'element-plus/dist/index.css'
import axios from "axios";
import { ElMessage, ElMessageBox } from 'element-plus'
import './assets/css/common.css';

axios.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    if (error.request.responseURL.match(/api\/v1\/auth\/login/)) {
	throw error
    }
    if (error.response.status != 401) {
	throw error
    }
    ElMessageBox.alert("権限がありません。またはセッションがタイムアウトしました", '認証エラー', {
	confirmButtonText: 'OK',
	callback: (action) => {
	    sessionStorage.clear()
	    router.replace({ name: 'login' })
	}
    })
})

const app = createApp(App);
app.use(store)
app.use(router)
app.use(ElementPlus, axios, ElMessage)


app.mount('#app');
app.config.globalProperties.$ElMessage = ElMessage;

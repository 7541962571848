<script >

// import { ref } from "vue";
export default ({
  setup() {
    return {}
  },
  methods: {


  }
})
</script>

<template>
  <div class="all">
    
  <RouterView /></div>
</template>

<style scoped>
.all{
  height: 100vh;
}
</style>

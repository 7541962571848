<template >
    <el-dialog v-model="$store.state.SubmitEvent" :show-close="false" width="40%" align-center
        element-loading-text="loading" :lock="true" :close-on-click-modal="false">
        <el-table v-loading="true" element-loading-text="処理中．．．" class="visable">
            loading……
        </el-table>
    </el-dialog>
</template>
<script>
export default ({
    name: 'Dialog-view',
    props: ["SubmitFlag"],
    setup() {
    }, methods: {


    }
})


</script>
<style scoped>
*:deep(.el-table__empty-text) {
    display: none !important;
}

*:deep(.el-loading-spinner) {
    overflow: visible;
    top: 0%;
}

.visable {
    overflow: visible !important;
}

.dialog-footer button:first-child {
    margin-right: 10px;
}
</style>
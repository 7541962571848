import { createStore } from 'vuex'

export default createStore({
  state: {
    SubmitEvent: false
  },
  getters: {
  },
  mutations: {
    OpenEvent(state) {
      state.SubmitEvent = true
    },
    CloseEvent(state) {
      state.SubmitEvent = false
    }
  },
  actions: {
  },
  modules: {
  }
})

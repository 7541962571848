<script lang="js">
import DialdogView from "@/views/components/Dialog-view.vue";
import { ref, reactive } from "vue";
import axios from 'axios';
import { genFileId } from 'element-plus'
import fileDownload from 'js-file-download'
export default {
    name: 'HomeView',
    components: {
	DialdogView
    }
    , setup() {
	const formList = reactive([])
	let formData = ref(null);

	return { formData, formList }
    }
    , methods: {
	stop() { },
	selectFile() {

	    document.getElementsByClassName('is-drag')[0].click()
	},
	typeCheck(File, Files) {
	    if (File.name.toUpperCase().includes(".XLSX") == false) {
		this.$refs.formData.handleRemove(File);
		this.$ElMessage({
		    message: ".xlsxファイルを選択してください",
		    type: 'error',
		    'show-close': true,
		});
	    }
	    else {
		if (this.formList.length > 1) {
		    this.formList.push(Files[0].raw)
		}
		else {
		    this.formList[0] = Files[0].raw
		}
	    }
	},
	sleep(t) {
	    return new Promise((resolve, reject) => {
		setTimeout( () => {
		    resolve()
		}, t)
	    })
	},
	async UploadProps(newFile) {
	    this.$refs.formData.clearFiles()
	    newFile[0].uid = genFileId()
	    // this.$refs.formData.handleRemove(oldFile, newFile)
	    await this.sleep(500);
	    this.$refs.formData.handleStart(newFile[0]);
	}
	,
	settimeShow() {
	    this.$store.commit('CloseEvent');
	    this.$ElMessage({

		message: "アップロードに失敗しました。",
		type: 'error',
		'show-close': true,
	    });
	},
	errorMessage(errorCode) {
	    let errorMess;
	    if (errorCode == 404) {
		errorMess = "errorCode" + ":" + errorCode + " Not Found"
		return errorMess
	    } else {
		errorMess = "errorCode" + errorCode + ":" + "申し訳ありません、失敗しました"
		return errorMess
	    }
	},
	// 握手判断（timeOut）
	upLoad(a) {
	    if (
		document.getElementsByClassName('el-upload-list__item-name').length == 0) {
		this.$ElMessage({
		    message: "ファイルがありません。",
		    type: 'error',
		    'show-close': true,
		});
		return
	    }

	    this.$store.commit('OpenEvent');
	    this.$refs.formData.submit = async () => {
		//let messageAlert = setTimeout(this.settimeShow, 5000)
		let formData = new FormData();
		formData.append('file', this.formList[0]);
		// https://96fe923b-b381-46c1-96a9-c25985e03f10.mock.pstmn.io/trial-estimation/task/:task_id/run
		try {
		    let response = await axios.post("/api/v1/trial-estimation/", formData, {
			'responseType': 'arraybuffer',
			headers: {
			    'Content-Type': 'multipart/form-data',
			    'withCredentials': true
			},
			timeout: 60000
		    })
		    console.log(response)
		    this.$store.commit('CloseEvent');
		    this.$refs.formData.clearFiles();
		    this.centerDialogVisible = false
		    await this.sleep(1000);
		    this.$store.commit('CloseEvent');
		    this.$ElMessage({
			message: "正常に終了しました",
			'show-close': true,
			type: 'success',
		    });
		    // const contentDisposition = response.headers['content-disposition'] // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
		    // const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
		    // const result = patt.exec(contentDisposition)
		    // const filename = decodeURI(escape(result[1])) // ファイル名修正処理
		    let blob = new Blob(
			[response.data],
			{ "type": "application/pdf" });
		    let link = document.createElement('a')
		    link.href = window.URL.createObjectURL(blob)
		    let name = response.headers["content-disposition"];
		    const result = name.split("filename*=UTF-8''")
		    const filename =decodeURIComponent(result[1])
		    link.download = filename
		    link.click()
		} catch( reason ) {
		    if (reason.code === 'ECONNABORTED') {
			this.settimeShow()
			return
		    }
		    if (!reason.response) {
			this.settimeShow()
			return
		    }
		    await this.sleep(4000);
		    if (reason.response.status == 490) {
			let body = JSON.parse(new TextDecoder().decode(new Uint8Array(reason.response.data)))
			this.$ElMessage({
			    message: body.message,
			    type: 'error',
			    'show-close': true,
			    duration: 0
			})
		    } else {
			this.$ElMessage({
			    message:
			    this.errorMessage(reason.response.status),
			    type: 'error',
			    'show-close': true,
			})
		    }
		    this.$store.commit('CloseEvent');
		    console.log(reason);

		    //warning待設計
		}
	    }
	    this.$refs.formData.submit();
	},
    }

}



</script>


<template>
    <div style="
                                                      padding-top: 6px;
                                                      width: 100%;
                                                      align-items: center;
                                                      text-align: center;
                                                      font-size: 40px;
                                                    margin-bottom: 20px;
                                                    color: #5983B0;">導入効果試算書作成</div>
  <div class="flex-justify-center">
    <div class="Home">

      <div class="bodyLeft">
        <!-- https://element-plus.org/zh-CN/component/button.html -->
        <el-upload id="W" :file-list="formList" :on-change="typeCheck" drag :http-request="upLoad" multiple
          :auto-upload="false" :limit="1" ref="formData" :on-exceed="UploadProps" class="dragBox">
          <div class="dragContent" @click.stop="stop">
            <div class="upLoadText">
              ここに各種情報を入力したテンプレートファイルをドラッグアンドドロップ、もしくは、アイコンをクリックしてファイルを選択して下さい。
            </div>
            <div>
              <div @click="selectFile"><img src="@/../public/icon/update.png" style="width: 220px;cursor: pointer;" /></div>
            </div>
          </div>
          <input class="btn btn-success" type="submit" value="作成" @click="upLoad" />
        </el-upload>

      </div>
      <div class="body">
        <div style="display: flex">
          <div>
            <a href="template/20240105_入力フォーマットAA006.xlsx" download="20240105_入力フォーマットAA006.xlsx">
              <img src="@/../public/icon/download.png"  style="width:70px;height:60px"  />
            </a>
          </div>
          <nav>
            <a>こちらのアイコンからテンプレートファイルをダウンロードして下さい。</a><br>
            <b>バージョンAA006 2024年1月5日更新</b>
          </nav>
        </div>
        <div class="line"> </div>
        <div style="display: flex;flex-direction: row;margin-left: 5px;">
          <img src="@/../public/icon/fileIcon.png" style="width:70px;height:70px" />
        
        <div style="margin-left: 5px;">選択されたファイル：</div><br>
        </div>

    <input class="btn" type="submit" value="作成" @click="upLoad" />
  </div>

</div>
</div>

  <DialdogView></DialdogView>

</template>

<style scoped>
.dark {
  background-color: rgba(37, 35, 35, 0.848);
}

.Home:deep(.el-upload):focus {
  border: 0;
}

.Home:deep(.el-upload--text) {
  pointer-events: fill;
  display: flex;
  justify-content: center;
}
.Home:deep(.el-upload--text) {
 border-radius:30px ;
}

.Home:deep(.el-upload-dragger) {
  padding: 0;
  /* border: #4b7cd1 2px dashed; */
  border: 0;
  position: relative;
  border-radius: 30px;
  width: 400px;
  height: 300px;
}

.dragBox {
  position: relative;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.Home:deep(.el-upload-list__item) {
  position: absolute;
  top: -140px;
  left: 133%;
  z-index: 999;
}


.Home:deep(.el-upload-list__item-name .el-icon) {
  display: none;
}
.btn{
  color: rgb(255, 255, 255);
  background-color: green;
  width: 150px;
  height: 50px;
  font-size: 20px;
  margin-top: 20px;
  align-self: center;
}
.Home {

display: flex;
/* flex-flow:  */
width: 1200px;
justify-content: center;
overflow: visible;
}

.dragContent {
border-radius: 30px;
user-select: none;
border: #4b7cd1 2px dashed;
box-sizing: border-box;
cursor: auto;
height: 100%;
width: 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}

.body {
margin-left: 60px;
width: 450px;
height: 300px;
padding-top: 16px;
position: relative;
display: flex;
flex-direction: column;

}

.body a {
align-items: left;
}

.bodyLeft {}

nav {
width: 300px;
margin-left: 10px;
font-size: 1rem;
}

nav a{

color: #2082c8;
margin-top: 2px;
}



a {
color: rgb(66, 66, 66);
text-decoration: none;
}

b {
color: darkblue;
}

.button {
display: flex;
cursor: pointer;
position: relative;
top: 50%;
width: 150px;
height: 60px;
align-items: center;
justify-content: center;
background-color: #4b7cd1;
color: white;
}

.buttonBox {
cursor: auto;
top: 0;
width: 100%;
height: 100%;
position: absolute;
display: flex;
justify-content: below;
display: flex;
}

.upLoadText {
padding-top: 10px;
width: 18rem;
display: flex;
color: black;
justify-content: flex-start;
text-align: left;
}

.buttonBig {
margin-top: 28px;
width: 15rem;
height: 4rem;
display: flex;
align-items: center;
align-self: center;
color: white;
background-color: #4b7cd1;
padding: 8px;
justify-content: center;
justify-self: flex-end;
}

.line {
margin-left: 5%;
width: 90%;
height: 1px;
margin-top: 40px;
margin-bottom: 15px;
background-color: #3465a4;
position: relative;
}
</style>
